
import Vue from 'vue'

export default Vue.extend({
  name: 'PressProjectDetail',

  data: () => {
    return {
      isLoading: true,
      project: null,
      showContextSwitch: false,
    }
  },

  computed: {
    appInfo () {
      return this.$store.state.appInfo
    },
    awards () {
      return this.$store.state.awards?.awards
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    id () {
      return this.$route.params.id
    },
  },

  methods: {

    prepareUrl(url: string) {
      let regex = /^(http|https)/;
      if(url.length > 3 && !url.match(regex)) {
        url = 'http://' + url;
      }

      return url;
    },

    initObject () {
      this.$store
        .dispatch('getProjectPress', this.$route.params.id)
        .then((p) => {
          if (p.projectDetails && this.currentAward) {
            const { awardType, year } = this.currentAward
            const pd = p.projectDetails.filter((o: any) => {
              return o.awardType == awardType && o.year == year
            })
            if (pd && pd[0]) {
              const pd0 = Object.entries(pd[0])
              pd0.forEach(o => {
                if (typeof o[1] !== 'undefined' && typeof p[o[0]] === 'undefined') {
                  p[o[0]] = o[1]
                }
              })
            }
          }

          this.project = p;
          this.isLoading = false
        })
    },


    switchContext (val: any) {
      if (!val) {
        return console.error('must select context')
      }

      let res = this.awards?.filter((aw: any) => aw.id == val)[0]
      if (res) {
        this.$store.commit('setCurrentAward', res)
      }
      return this.initObject()
    },
  },

  mounted () {
    this.initObject()
  },
})
